<template>
  <div>
    <!-- <a v-if="!isMobile" :class="['download', type]" @click="download" title="安卓下载" :href="apkurl">
      <img src="../assets/2023/pc/android.png" />
      安卓下载
    </a> -->
    <!-- <a v-if="isMobile" :class="['download2', type]" @click="download" title="点击下载" :href="apkurl">
      <img src="../assets/2023/download.png" />
      点击下载
    </a> -->
    <!-- 楼下是百度屏蔽了底部悬浮元素，所以换方法 -->
    <!-- <a :class="['download2', type]" @click="download" title="点击下载" :href="apkurl">
      <img src="../assets/2023/download.png" />
      点击下载
    </a> -->
    <a :class="['download3', type]" title="点击下载" :href="apkurl"></a>
  </div>

</template>
<script>
// import { isMobile } from "./utils.js"
export default {
  props: ["type"],
  data() {
    return {
      // isMobile,
      apkurl: null
    }
  },
  methods: {
    download() {
      //this.$bus.$emit("downLoadPc");
    }
  },
  mounted() {
    this.$bus.$on('apkurl', (apkurl) => {
      this.apkurl = apkurl
    })
  }
};
</script>
<style scoped lang="scss">
.download {
  display: flex;
  align-items: center;
  justify-content: center;
  // display: block;
  width: 244px;
  height: 74px;
  line-height: 74px;
  background: #3da7fe;
  border: 2px solid #2197f9;
  border-radius: 16px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  left: 310px;
  top: 378px;
  cursor: pointer;
  transition: all 300ms linear;

  img {
    width: 19px;
    height: 20px;
    // display: block;
    // float: left;
    // margin: 21px 18px 21px 41px;
    margin-right: 7px;
  }

  &:hover {
    background: #7ec5ff;
  }

  &.mini {
    box-sizing: border-box;
    width: 140px;
    height: 40px;
    line-height: 38px;
    border: 2px solid #2197f9;
    border-radius: 8px;
    font-size: 16px;

    img {
      width: 19px;
      height: 20px;
      // margin: 10px 7px 10px 25px;
      margin-right: 7px;
    }
  }

  &.medium {
    box-sizing: border-box;
    width: 244px;
    height: 74px;
    font-size: 28px;

    img {
      width: 33px;
      height: 34px;
      margin-right: 13px;
    }
  }

  &.large {
    box-sizing: border-box;
    width: 244px;
    height: 93px;
    font-size: 28px;

    img {
      width: 33px;
      height: 34px;
      margin-right: 13px;
    }
  }
}

.download2 {
  display: flex;
  align-items: center;
  justify-content: center;
  // display: block;
  width: 244px;
  height: 74px;
  line-height: 74px;
  background: #3da7fe;
  border: 2px solid #2197f9;
  border-radius: 16px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  left: 310px;
  top: 378px;
  // cursor: pointer;
  transition: all 300ms linear;

  img {
    width: 19px;
    height: 20px;
    // display: block;
    // float: left;
    // margin: 21px 18px 21px 41px;
    margin-right: 7px;
  }

  &.mobile {
    box-sizing: border-box;
    width: 74.4%;
    height: 51px;
    font-size: 22px;

    img {
      width: 24px;
      height: 25px;
      margin-right: 12px;
    }
  }
}

.download3 {
  position: relative;
  width: 74.4%;
  height: 51px;
  margin: 0 auto 35px;
  display: block;

  // &::before {
  //   content: '';
  //   position: fixed;
  //   left: 50%;
  //   transform: translateX(-80px);
  //   bottom: 46px; // 35 + 13
  //   background-image: url('../assets/2023/download_0.png');
  //   background-repeat: no-repeat;
  //   width: 24px;
  //   height: 25px;
  //   background-size: 24px 25px;
  //   z-index: 1;
  // }

  &::after {
    content: url('../assets/2023/download_2.svg');
    box-sizing: border-box;
    position: fixed;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 74.4%;
    height: 51px;
    overflow: hidden;

    background: #3da7fe;
    border: 2px solid #2197f9;
    border-radius: 16px;

    // font-size: 22px;
    // font-family: Microsoft YaHei;
    // font-weight: bold;
    // color: #ffffff;
    // line-height: 50px;

    // padding-left: 30vw;
    padding-top: 8px;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
}
</style>
