<template>
    <div class="container">
        <!-- <a class="go" :href="type.link"> -->
        <a class="go" @click="changePage(type.goto)">
            <span>{{ type.name }}</span>
            <img src="../assets/2023/more.png" alt="">
        </a>
        <div class="content">
            <span>copyright@2019 xiaominet.com All Right Reserved</span>
            <span>武汉小咪网络科技有限公司版权所有</span>
            <div class="one-line">
                <img src="../assets/2023/copy-footer.png" alt="">
                <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011502001211" target="_blank">
                    <span>
                        鄂公网安备42011502001211
                    </span>
                </a>
                <a href="https://beian.miit.gov.cn/" target="_blank">
                    <span>
                        &nbsp;&nbsp;鄂ICP备14013067号-8
                    </span>
                </a>
            </div>
            <a href="/filing" target="_blank">
                <span>鄂网文〔2018〕9552-285号</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type"],
    data() {
        return {

        }
    },
    methods: {
      changePage(name) {
        this.$bus.$emit('changePage', name)
      }
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 151px;
    min-width: 310px;
    background: #EBF4FF;

    span {
      display: block;
    }

    .go {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        margin: 23px 0;

        img {
            width: 14px;
            height: 14px;
            margin-left: 6px;
            margin-top: -1px;
        }
    }

    .content {
        position: relative;
        top: -10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            margin-top: 8px;
            font-size: 12px;
            line-height: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #161616;
        }

        .one-line {
            display: flex;
            align-items: flex-end;

            img {
                width: 11px;
                height: 12px;
                margin-right: 6px;
            }

            span {
                margin-top: 8px;
                font-size: 12px;
                line-height: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #161616;
            }
        }
    }
}
</style>