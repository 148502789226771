// 初始化
export const init = () => {
    let app = document.querySelector('#app');
    app.style.minWidth = '100vw';
    app.style.width = '100vw';
    app.style.minHeight = '100vh';
    app.style.height = 'auto';
    // app.querySelectorAll('.__header')[0].style.display = 'none';
    // app.querySelectorAll('.header_block')[0].style.display = 'none';
    // app.querySelectorAll('.footer')[0].style.display = 'none';
    // app.querySelectorAll('.box')[0].style.minWidth = '100vw';
}

// 判断是不是移动端
export const isMobile = (() => {
    const UA = window.navigator.userAgent.toLowerCase();
    let ios = (UA.match(/iphone/i) == 'iphone' || UA.match(/ipad/i) == 'ipad') ? !0 : !1,
      adr = UA.match(/android/i) == 'android' ? !0 : !1;
    return ios || adr;
  })()