<template>
  <div id="app">
    <Mindex v-show="showindex" />
    <Mabout v-show="!showindex" />
  </div>
</template>

<script>
import Mindex from './components/index.vue'
import Mabout from './components/about.vue'

export default {
  name: 'App',
  components: {
    Mindex,
    Mabout
  },
  data() {
    return {
      showindex: true
    }
  },
  methods: {
    choosePage(name) {
      if (name == 'index') {
        // console.log('go to index');
        this.showindex = true
        localStorage.name = 'index'
      } else if (name == 'about') {
        // console.log('go to about');
        this.showindex = false
        localStorage.name = 'about'
      }
      document.documentElement.scrollTop = 0;
    },
  },
  created() {
    this.choosePage(localStorage.name)
  },
  mounted() {
    this.$bus.$on('changePage', (name) => {
      this.choosePage(name)
    })
  },
  beforeCreate() {
    localStorage.removeItem('name')
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  /* 移动端去掉点击后的蓝色框框 */
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

a {
  text-decoration: none;
}
html {
  font: 12px/1.5 Arial, PingfangSC, Microsoft YaHei, Helvetica Neue, Helvetica,
    STHeiTi, sans-serif;
}
</style>
