<template>
  <div class="header">
    <img src="../assets/2023/logo.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #ffffff;
  z-index: 50;

  img {
    position: relative;
    left: 3.2%;
    width: 146px;
    height: 29px;
  }
}
</style>
